import React from "react"
import Heading from '../../components/UI/Heading/Heading';
import SubHeading from '../../components/UI/SubHeading/SubHeading';
import RegisterTable from '../../components/RegisterTable/RegisterTable'
import styles from "./Register.module.css";
import FloatNextButton from "../../components/FloatNextButton/FloatNextButton";
import Footer from "../../components/Footer/Footer";
import { register_description } from "../../data";

const Register = () => {

    return (
        <>
            <div>
                <Heading text="REGISTER" />
                <div className={styles.wrapper} >
                    <SubHeading text="PAPER REGISTRATION" />
                    <p className={styles.description}>
                        {register_description}
                        {/* <i className={styles.button_wrapper}>CLICK TO REGISTER!</i> */}
                        
                    </p>
                    <RegisterTable />
                    <button onClick={() => window.open('https://forms.gle/ff5H4WiXiCwAg1VV8')} className={styles.submit_button}>
                            <div>
                                <div className={styles.pencil}></div>
                                <div className={styles.folder}>
                                    <div className={styles.top}>
                                        <svg viewBox="0 0 24 27">
                                            <path d="M1,0 L23,0 C23.5522847,-1.01453063e-16 24,0.44771525 24,1 L24,8.17157288 C24,8.70200585 23.7892863,9.21071368 23.4142136,9.58578644 L20.5857864,12.4142136 C20.2107137,12.7892863 20,13.2979941 20,13.8284271 L20,26 C20,26.5522847 19.5522847,27 19,27 L1,27 C0.44771525,27 6.76353751e-17,26.5522847 0,26 L0,1 C-6.76353751e-17,0.44771525 0.44771525,1.01453063e-16 1,0 Z"></path>
                                        </svg>
                                    </div>
                                    <div className={styles.paper}></div>
                                </div>
                            </div>
                          Click here to Register
                        </button>
                </div>
                <FloatNextButton link="/keynote" />
            </div>
            <Footer />
        </>
    )
}

export default Register